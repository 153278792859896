
import { defineComponent, ref, onMounted, onUpdated, reactive } from "vue";
import { useI18n } from "vue-i18n/index";

//import { translate } from "element-plus";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "customers-listing",
  components: {
    //Datatable,
  },
  setup() {
    const { t, te, i18n } = useI18n();

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    return {
      t,
    };
  },
  data() {
    return {
      tourItems: "",
      currentPage: 1,
      maxPage: 1,
    };
  },

  watch: {},
  methods: {},
});
